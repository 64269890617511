import React from "react";
import { Analytics } from "@vercel/analytics/react";

import {
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  SpecialMenu,
} from "./container";
import Hero2 from "./container/Hero2/hero2";
import { Navbar } from "./components";
import "./App.css";

const App = () => (
  <div>
    <Analytics />
    <Navbar />
    <Hero2 />
    {/* <Header /> */}
    {/* <Intro /> */}
    <SpecialMenu />
    {/* <Gallery /> */}
    <FindUs />
    <Footer />
  </div>
);

export default App;
