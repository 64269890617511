import React from "react";

import { SubHeading, MenuItem } from "../../components";
import { data, images } from "../../constants";
import "./SpecialMenu.css";

// Лучше перезагрузить локал хост для тяги данных

const SpecialMenu = () => (
  <div className="app__specialMenu app__bg flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="100% Halal Certified" />
      <h1 className="headtext__cormorant">Special Menu</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_combo  flex__center">
        <p className="app__specialMenu-menu_heading">Combos</p>
        <div className="app__specialMenu_menu_items">
          {data.combo.map((combo, index) => (
            <MenuItem
              key={combo.title + index}
              title={combo.title}
              price={combo.price}
              tags={combo.tags}
              icon={combo.icon}
              allergy={combo.allergy}

            />
          ))}
        </div>
        {/* <div className="menu__wrapper_img">
        <img src={images.thumbcombo} alt="header_img" style={{ marginRight: 0 }}/>
        </div> */}
      </div>
      <div className="app__specialMenu-menu_sides  flex__center">
        <p className="app__specialMenu-menu_heading">Sides</p>
        <div className="app__specialMenu_menu_items">
          {data.sides.map((sides, index) => (
            <MenuItem
              key={sides.title + index}
              title={sides.title}
              price={sides.price}
              tags={sides.tags}
              allergy={sides.allergy}
            />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_desserts  flex__center">
      <p className="app__specialMenu-menu_heading flex__center">Desserts</p>
          {data.desserts.map((desserts, index) => (
            <MenuItem
              key={desserts.title + index}
              title={desserts.title}
              price={desserts.price}
              tags={desserts.tags}
              allergy={desserts.allergy}
            />
          ))}
        <p className="app__specialMenu-menu_heading">Drinks</p>
        <div className="app__specialMenu_menu_items">
          {data.drinks.map((drinks, index) => (
            <MenuItem
              key={drinks.title + index}
              title={drinks.title}
              price={drinks.price}
              tags={drinks.tags}
              allergy={drinks.allergy}
            />
          ))}
          {/* <div className="menu__wrapper_img">
        <img src={images.thumbfries} alt="header_img" style={{ marginRight: 0 }}/>
        <img src={images.thumbmacncheese} alt="header_img" style={{ marginRight: 0 }}/>
        </div> */}
        </div>
      



      </div>
    </div>

    
  </div>
);

export default SpecialMenu;
