import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
        Find Us
      </h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">
          725 Garrett Lane, London, SW17 OPD
        </p>
        <p
          className="p__cormorant"
          style={{ color: "#ff5900", margin: "2rem 0" }}
        >
          Opening Hours
        </p>
        <p className="p__opensans">Monday: CLOSED</p> /br
        <p className="p__opensans">Tuesday-Sunday: 1:00 PM - 10:00 PM</p> /br
        <p className="p__opensans">Friday: 2:00 PM - 10:00 PM</p>
      </div>
      {/* <button
        type="button"
        className="custom__button"
        style={{ marginTop: "2rem" }}
      >
        <a href="#home">Visit Us</a>
      </button> */}
    </div>

    <div className="app__wrapper_img2">
      <img src={images.vertcombo2} alt="finus_img" />
      <img src={images.vertcombo4} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
