import images from './images';

const combo = [
  {
    title: 'Delight Platter',
    price: '£10.00',
    tags: '3 Wings, Mac & Cheese, Curly Fries, Corn On The Cob or Waffles',
    allergy: 'Contains: Dairy'
  },
  {
    title: 'Deluxe Platter',
    price: '£15.00',
    tags: '5 Wings, Mac & Cheese, Prawns, Curly Fries, Coleslaw, Corn On The Cob & Waffles',
    allergy: 'Contains: Shellfish, Dairy'
  },
  {
    title: 'Premium Platter',
    price: '£18.00',
    tags: 'Salmon, Mac & Cheese, Prawns, Curly Fries, Coleslaw, Corn On The Cob & Waffles',
    allergy: 'Contains: Fish, Shellfish, Dairy'
  },
  {
    title: 'Salmon with Mac & Cheese',
    price: '£12.00',
    // tags: 'Crispy chicken over French fries topped with cheese and Soulful\'s Special Sauce',
    allergy: 'Contains: Fish, Dairy'
  },
  {
    title: 'Loaded Fries',
    price: '£7.00',
    tags: 'Crispy chicken over French fries topped with cheese and Soulful\'s Special Sauce',
  },
];

const sides = [
  {
    title: '6 Grilled Wings',
    price: '£5.00',
    tags: 'BBQ or Jerk',
  },
  {
    title: 'Mac & Cheese',
    price: '£5.00',
    tags: '',
    allergy: 'Contains: Dairy'
  },
  {
    title: '10x Prawns',
    price: '£5.00',
    tags: 'Battered Prawns garnished with Sweet Chilli Sauce',
    allergy: 'Contains: Shellfish'
  },
  {
    title: 'Salmon',
    price: '£7.00',
    tags: 'Topped with Teriyaki Sauce',
    allergy: 'Contains: Fish'
  },
  {
    title: '3 Grilled Wings and Chips',
    price: '£5.00',
    tags: '',
  },
  {
    title: 'Curly Fries',
    price: '£3.00',
    tags: '',
  },
  {
    title: 'Sauces',
    price: '£0.50',
    tags: 'Algerian | BBQ | Jerk',
  },
];

const classics = [
  {
    title: 'The Old Classic',
    price: '£5.00',
    tags: '3 Buffalo Wings | Curly Fries',
  },
  {
    title: 'Fully Loaded Fries',
    price: '£7.00',
    tags: 'Classic | Spicy | Cheesy',
  },
];

const drinks = [
  {
    title: 'KA (Cans)',
    price: '£1.00',
    tags: 'Pineapple | Black Grape | Fruit Punch',
  },
  {
    title: 'Rio',
    price: '£1.00',
    tags: 'Can',
  },
  {
    title: 'Lemonade',
    price: '£1.00',
    tags: 'Can',
  },
  {
    title: 'Ginger Beer',
    price: '£1.00',
    tags: 'Can',
  },
  {
    title: 'Bigga (Bottles)',
    price: '£2.00',
    tags: 'Pineapple | Black Grape | Fruit Punch',
  },
  // {
  //   title: 'Karma Drinks (Glass)',
  //   price: '£2.00',
  //   tags: 'Cola | Lemonade | Ginger',
  // },
  // {
  //   title: 'Mojito',
  //   price: '£5.00',
  //   tags: 'Lemon & Mint | Strawberry & Mint',
  // },
  {
    title: 'Water',
    price: '£1.00',
    tags: 'Bottled',
  },
];

const desserts = [
  {
    title: 'Milkshake',
    price: '£5.00',
    tags: 'Oreo | Biscoff',
    allergy: 'Contains: Dairy'
  },
  {
    title: 'Milk cake',
    price: '£6.00',
    tags: 'Oreo | Biscoff',
    allergy: 'Contains: Dairy, Wheat, Flour, Eggs, Soya (biscuits)'
  },
];


const sauces = [
  {
    title: 'Sauces',
    price: '£0.50',
    tags: 'Algerian | BBQ | Jerk',
  },
];


export default { combo, sides, classics, desserts, drinks, sauces };
