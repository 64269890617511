import React from "react";
import { images } from "../../constants";

const SubHeading = ({ title, children }) => (
  <div style={{ marginBottom: "1rem" }}>
    {title && <p className="p__opensans">{title}</p>}
    {children}
    <img src={images.spoon} alt="spoon_image" className="spoon__img" />
  </div>
);

export default SubHeading;
