import React from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";

import { FaTiktok, FaInstagram, FaSnapchatGhost } from "react-icons/fa";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./hero2.css";

const Hero2 = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="hero_app__gallery flex__center" id="hero2">
      <div className="hero_app__gallery-content">
      <SubHeading>
        <div className="app__footer-links_icons">
          <a href="https://www.instagram.com/soulfuldelightsldn">
            <FaInstagram />
          </a>
          <a href="https://www.tiktok.com/@soulfuldelightsldn">
            <FaTiktok />
          </a>
          <a href="https://www.snapchat.com/add/soulful-delight">
            <FaSnapchatGhost />
          </a>
        </div>
      </SubHeading>
        <h1 className="headtext__cormorant">Authentic Soulfood in South London</h1>
        <p
          className="p__opensans"
          style={{ color: "#AAAAAA", marginTop: "1rem" }}
        >
          Open Tuesday-Sunday from 3:00 PM - 12:00 AM in Garrett Lane, Tooting. Order now for collection or delivery.
        </p>
        <div className="two-column-container">
          <div className="column">
          <a href="https://www.ubereats.com/gb/store/soulful-delights/31xxv60RXB-7fDaXxjXfDg" className="hero__button" style={{ backgroundColor: '#06C167' }}>
            Order on UberEats
          </a>
          </div>
          <div className="column">
              <a href="https://deliveroo.co.uk/menu/london/upper-tooting/soulful-delights" className="hero__button" style={{ backgroundColor: '#00c1b2' }}>
                Order on Deliveroo
              </a>
          </div>
          <div className="column">
              <a href="https://www.just-eat.co.uk/restaurants-soulful-delights-tooting-broadway" className="hero__button" style={{ backgroundColor: '#ff8100' }}>
                Order on Just Eat
              </a>
          </div>
          </div>
      </div>
      <div className="hero_app__gallery-images">
        <div className="hero_app__gallery-images_container" ref={scrollRef}>
          {[
            images.vertcombo,
            images.vertcombo3,
            images.vertdessert,
            images.vertfries,
            images.vertmain,
            images.vertmilkcake,
            images.vertmilkshake,
          ].map((image, index) => (
            <div
              className="hero_app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery_image" />
                <BsInstagram className="gallery__image-icon" />
            </div>
          ))}
        </div>
        <div className="hero_app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero2;
