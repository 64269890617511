import bg from '../assets/bg.png';
import spoon from '../assets/download.svg';
import sdlogo from '../assets/logo new transparent.png';
import sdfoodpng from '../assets/sdfood.png';
import sdfoodwebp from '../assets/sdfood.webp';
import sdfood1png from '../assets/sdfood (1).png';
import sdfood1webp from '../assets/sdfood (1).webp';
import sdfood2png from '../assets/sdfood (2).png';
import sdfood2webp from '../assets/sdfood (2).webp';
import sdfood3png from '../assets/sdfood (1).png';
import sdfood3webp from '../assets/sdfood (1).webp';
import mojito from '../assets/mojito.jpeg';
import oreowaffle from '../assets/oreowaffle.jpeg';
import sweetpotatofries from '../assets/sweetpotatofries.webp';
import food1 from '../assets/sdfood (1).webp';
import food2 from '../assets/sdfood (9).webp';
import food3 from '../assets/sdfood (4).webp';
import food4 from '../assets/sdfood (7).webp';
import vertcombo from '../assets/vert-combo.jpeg';
import vertcombo2 from '../assets/vert-combo2.jpeg';
import vertcombo3 from '../assets/vert-combo3.jpeg';
import vertcombo4 from '../assets/vert-combo4.jpeg';
import vertdessert from '../assets/vert-dessert.jpeg';
import vertfries from '../assets/vert-loadedfries.jpeg';
import vertmain from '../assets/vert-main.jpeg'
import vertmilkcake from '../assets/vert-milkcake.jpeg';
import vertmilkshake from '../assets/vert-milkshake.jpeg';
import squarecombo from '../assets/square-combo.jpeg';
import squaremilkcake from '../assets/square-milkcake.jpeg';
import thumbcombo from '../assets/thumb-combo.jpeg';
import thumbcombo2 from '../assets/thumb-combo2.jpeg';
import thumbfries from '../assets/thumb-fries.jpeg';
import thumbmacncheese from '../assets/thumb-macncheese.jpeg';
import hormain from '../assets/hor-main.jpeg';
import hormain2 from '../assets/hor-main2.jpeg';
import fhr from '../assets/fhrs_5.jpeg';

export default {
  bg,
  spoon,
  sdlogo,
  sdfoodpng,
  sdfood1png,
  sdfood2png,
  sdfood3png,
  sdfoodwebp,
  sdfood1webp,
  sdfood2webp,
  sdfood3webp,
  oreowaffle,
  mojito,
  sweetpotatofries,
  food1,
  food2,
  food3,
  food4,
  vertcombo,
  vertcombo2,
  vertcombo3,
  vertcombo4,
  vertdessert,
  vertfries,
  vertmain,
  vertmilkcake,
  vertmilkshake,
  squarecombo,
  squaremilkcake,
  thumbcombo,
  thumbcombo2,
  thumbfries,
  thumbmacncheese,
  hormain,
  hormain2,
  fhr,
};
